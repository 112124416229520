import  $ from 'jquery';
import React,{useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import Search_Filters from '../component_version2/search_filters';
import Comp_Sorting_Bar from '../component_version2/comp_sorting_bar';
import {appGetUrlPath,CallMapMethod_CBack} from '../function_lib/common_lib';

import './product_listing_2.css';

export function searchProductsLists(lsData){
    var appUrtPath=appGetUrlPath();
    var listData=JSON.parse(lsData);
    let countMsg="";
    if(listData.length==0){
       // countMsg="No Products Found";
    }else if(listData.length==1){
        countMsg=listData.length +" Product Found";
    }else{
        countMsg=listData.length+" Products Found";
    }
    $("#l_pro_counts").html(countMsg);
    let colsSet="col-lg-12 col-md-12";
        var strHeader="<div class=\"shop-category-area pb-100px pt-10\">"+
        "<div class=\"container\">"+
            "<div class=\"row ser_comp_f\">"+
                "<div class=\""+colsSet+" order-lg-last order-md-first\">"+
                "<div class=\"shop-bottom-area\">"+
                "<div class=\"row\">";
               
         var strFooter="</div></div></div></div></div></div>";
    
         var strResp="";
    let offerPer=0;
    let offerMsg="";
    let oldPrice="";
    let youSaved="";
        if(listData.length===0){
            strResp="<div class=\"col-lg-12\"><center><b>"+"No Data Found "+"</b></center></div>";
        }
      for(var i=0;i<listData.length;i++){
        offerMsg="";
        oldPrice=""; 
        youSaved=""; 

        var imgPath=["default-product.png"];  
        if(listData[i]["img_path"]!=""){
         imgPath=listData[i]["img_path"].split(',');
          }
          let proCName=listData[i]["product_name"].replace(/\s/g, '_');
          proCName=proCName.replace(/\//g,'-');
          
          //let proCName=listData[i]["product_name"];
          //proCName=proCName.replace(/\//g,'-');
      
         let proUrl= encodeURI("../product/"+proCName+"?rf="+listData[i]["rf_id"]);
     offerPer=parseInt(listData[i]["offer_per"]);
     if(listData[i]["pre_cost"]!==listData[i]["cost"]){
         oldPrice="<span class=\"old\">₹"+listData[i]["pre_cost"]+"</span>";
         youSaved="<span style=\"color:#ee7600\">"+"save ₹ "+parseFloat(parseFloat(listData[i]["pre_cost"])-parseFloat(listData[i]["cost"])).toFixed(2)+"/-";
         if(offerPer!==0){
             //youSaved+="("+listData[i]["offer_per"]+"% off)</span>";
             youSaved+="</span>";
         }else{
             youSaved+="</span>";
         }
     }
   
     if(offerPer!==0){
        offerMsg="<span class=\"new\">"+offerPer+"% off</span>";
     }
     let addToCartButton="<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart ext_addto \">Add To Cart</button>";
     if(parseFloat(listData[i]["max_limit_qty"])<=0){
        offerMsg="<span class=\"out\">"+"Out of Stock"+"</span>";
        addToCartButton="<button type=\"button\" title=\"Not in Stock\" class=\"_addToCart111 ext_addto \">Not in Stock</button>";
     }

     let skuSec="";
   
     /*
   let skuSec="<div class=\"d-flex justify-content-between mb-3\">"+
   "<h5 class=\"mb-0 small\">"+listData[i]["product_code"]+"</h5>"+
   "<div class=\"ps-2 small\">"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "</div>"+
   "</div>";
   */

          strResp+="<div class=\"col-lg-4 col-md-6 col-12\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
          "<div class=\"product g-box-shadow m-2 mb-5\">"+
          "<form>"+
          "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["product_name"]+"\" />"+
          "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["product_code"]+"\"/>"+
          "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["cost"]+"\" />"+
          "<input type=\"hidden\" name=\"pre_cost\" value=\""+listData[i]["pre_cost"]+"\" />"+
          "<input type=\"hidden\" name=\"offer_per\" value=\""+listData[i]["offer_per"]+"\" />"+
          "<input type=\"hidden\" name=\"comp_name\" value=\""+"0"+"\" />"+
          "<input type=\"hidden\" name=\"item_qty\" value=\""+"1"+"\" />"+
          "<input type=\"hidden\" name=\"enc_tmp_id\" value=\""+listData[i]["rf_id"]+"\" />"+
      
          "<input type=\"hidden\" name=\"imgs\" value=\""+imgPath[0]+"\" />"+
          "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
              "<div class=\"thumb pro-img-div\">"+
                  "<a href=\""+proUrl+"\" class=\"image\">"+
                      "<img src=\""+appUrtPath+imgPath[0]+"\" alt=\"Product\" />"+
                      "<img class=\"hover-image\" src=\""+appUrtPath+imgPath[0]+"\" alt=\"Product\" />"+
                  "</a>"+
                  "<span class=\"badges\">"+
                  offerMsg+
                  "</span>"+
                  "<div class=\"actions\">"+
                    //   "<a href=\"wishlist.html\" class=\"action wishlist\" title=\"Wishlist\"><i class=\"icon-heart\"></i></a>"+
                    //   "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
                    //   "<a href=\"compare.html\" class=\"action compare\" title=\"Compare\"><i class=\"icon-refresh\"></i></a>"+
                  "</div>"+
                  //"<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart add-to-cart\">Add To Cart</button>"+
              "</div>"+
              "<div class=\"content content-h\">"+
              skuSec+
                  "<h5 class=\"title\"><a href=\""+proUrl+"\">"+listData[i]["product_name"]+"</a></h5>"+
                  "<span class=\"price\">"+
                      "<span class=\"new\">₹"+listData[i]["cost"]+"</span>"+
                      oldPrice+
                  "</span>"+
                  "<span class=\"price\">"+youSaved+"</span>"+
                  addToCartButton+     
              "</div>"+
          "</form>"+"</div>"+
      "</div>";
      }
      return strResp;
} 
export function Product_Listing(lsData,isSearchBar=false,filters){
 
    var appUrtPath=appGetUrlPath();
    
var listData=JSON.parse(lsData);


let colsSet="col-lg-12 col-md-12";
let strSearchBar="";
if(isSearchBar){
  colsSet="col-lg-9 col-md-9";
//   searchbar logic
//strSearchBar="<div class=\"col-lg-3 col-md-3\">";
strSearchBar+=ReactDOMServer.renderToString(<Search_Filters filters={filters} />);
strSearchBar+="<div class=\"mobile-filter-button\">"+
    "<a href=\"#0\" class=\"btn btn-warning open_filters filter-button\">"+
        "<i class=\"ion-ios-color-filter-outline12 ti-filter12 fa fa-filter white my-float\">Filter</i>"+
    "</a>"+
   "</div>";
//strSearchBar+="</div>";
}

let strSorting=ReactDOMServer.renderToString(<Comp_Sorting_Bar count={listData.length}/>);



    var strHeader="<div class=\"shop-category-area pb-100px pt-10\">"+
    "<div class=\"container\">"+
        "<div class=\"row ser_comp_f\">"+
             strSearchBar+
            "<div class=\""+colsSet+" order-lg-last order-md-first\">"+
            "<div class=\"shop-bottom-area\">"+
            strSorting+"<div class=\"row\" id=\"pro_list_parent\">";
           
     var strFooter="</div></div></div></div></div></div>";

    var strResp="";
    let offerPer=0;
    let offerMsg="";
    let oldPrice="";
    let youSaved="";

    if(listData.length===0){
        strResp="<div class=\"col-lg-12\"><center><b>"+"No Data Found "+"</b></center></div>";
    }
  for(var i=0;i<listData.length;i++){
    offerMsg="";
    oldPrice=""; 
    youSaved=""; 

    var imgPath=["default-product.png"];  
    if(listData[i]["img_path"]!=""){
     imgPath=listData[i]["img_path"].split(',');
      }
      let proCName=listData[i]["product_name"].replace(/\s/g, '_');
      proCName=proCName.replace(/\//g,'-');
      
      //let proCName=listData[i]["product_name"];
      //proCName=proCName.replace(/\//g,'-');
  
     let proUrl= encodeURI("../product/"+proCName+"?rf="+listData[i]["rf_id"]);

     offerPer=parseInt(listData[i]["offer_per"]);
     if(listData[i]["pre_cost"]!==listData[i]["cost"]){
         oldPrice="<span class=\"old\">₹"+listData[i]["pre_cost"]+"</span>";
         youSaved="<span style=\"color:#ee7600\">"+"You save ₹ "+parseFloat(parseFloat(listData[i]["pre_cost"])-parseFloat(listData[i]["cost"])).toFixed(2)+"/-";
         if(offerPer!==0){
             youSaved+="("+listData[i]["offer_per"]+"% off)</span>";
         }else{
             youSaved+="</span>";
         }
     }
   
     if(offerPer!==0){
        offerMsg="<span class=\"new\">"+offerPer+"% off</span>";
     }

     let addToCartButton="<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart ext_addto \">Add To Cart</button>";
     if(parseFloat(listData[i]["max_limit_qty"])<=0){
        offerMsg="<span class=\"out\">"+"Out of Stock"+"</span>";
        addToCartButton="<button type=\"button\" title=\"Not in Stock\" class=\"_addToCart111 ext_addto \">Not in Stock</button>";
     }
     let skuSec="";

   /*  
   let skuSec="<div class=\"d-flex justify-content-between mb-3\">"+
   "<h5 class=\"mb-0 small\">"+listData[i]["product_code"]+"</h5>"+
   "<div class=\"ps-2 small\">"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "<small class=\"fa fa-star text-primary\"></small>"+
   "</div>"+
   "</div>";
   */

      strResp+="<div class=\"col-lg-4 col-md-6 col-sm-6 col-1222\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
      "<div class=\"product g-box-shadow m-2 mb-5\">"+
      "<form>"+
      "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["product_name"]+"\" />"+
      "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["product_code"]+"\"/>"+
      "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["cost"]+"\" />"+
      "<input type=\"hidden\" name=\"pre_cost\" value=\""+listData[i]["pre_cost"]+"\" />"+
      "<input type=\"hidden\" name=\"offer_per\" value=\""+listData[i]["offer_per"]+"\" />"+
      "<input type=\"hidden\" name=\"comp_name\" value=\""+"0"+"\" />"+
      "<input type=\"hidden\" name=\"item_qty\" value=\""+"1"+"\" />"+
      "<input type=\"hidden\" name=\"enc_tmp_id\" value=\""+listData[i]["rf_id"]+"\" />"+
  
      "<input type=\"hidden\" name=\"imgs\" value=\""+imgPath[0]+"\" />"+
      "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
          "<div class=\"thumb pro-img-div\">"+
              "<a href=\""+proUrl+"\" class=\"image\">"+
                  "<img src=\""+appUrtPath+imgPath[0]+"\" alt=\"Product\" />"+
                  "<img class=\"hover-image\" src=\""+appUrtPath+imgPath[0]+"\" alt=\"Product\" />"+
              "</a>"+
              "<span class=\"badges\">"+
                  offerMsg+
              "</span>"+
              "<div class=\"actions\">"+
                //   "<a href=\"wishlist.html\" class=\"action wishlist\" title=\"Wishlist\"><i class=\"icon-heart\"></i></a>"+
                //   "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
                //   "<a href=\"compare.html\" class=\"action compare\" title=\"Compare\"><i class=\"icon-refresh\"></i></a>"+
              "</div>"+
              //"<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart add-to-cart\">Add To Cart</button>"+
          "</div>"+
          "<div class=\"content content-h\">"+
          skuSec+
              "<h5 class=\"title\"><a href=\""+proUrl+"\">"+listData[i]["product_name"]+"</a></h5>"+
              "<span class=\"price\">"+
                  "<span class=\"new\">₹"+listData[i]["cost"]+"</span>"+
                  oldPrice+
              "</span>"+
              "<span class=\"price\">"+youSaved+"</span>"+
              addToCartButton+
          "</div>"+
      "</form>"+"</div>"+
  "</div>";
  }


  return strHeader+strResp+strFooter;

}
export default Product_Listing;