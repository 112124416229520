import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser,CallMapMethod_CBack,
ajaxindicatorstart,ajaxindicatorstop} from '../function_lib/common_lib';
import {getRoleDetails,CallCMSMapMethod} from '../function_lib/common_cms';
import Product_Listing from '../sample_comp/product_listing';
import Front_Header from '../pages/front_header';
import Footer from '../pages/footer';
import Top_Banner from '../sample_comp/top_banner';
import Top_Specified from '../pages/top_specified';
import Home_Product from '../pages/home_product';
import Product_Slider from '../sample_comp/product_slider';
import Features_Slider  from '../sample_comp/features_slider';
import Feature_Web_List from '../sample_comp/feature_web_list';
import Article_Listing from '../sample_comp/article_listing';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import ImageGallery from '../sample_comp/image_gallery';
import Video_Slider from '../sample_comp/video_slider';
import Collapse_Pan from '../sample_comp/collapse_pan';

import Comp_Blog_Land from '../sample_comp/comp_blog_land';
import Account_Opt_Link from './account_opt_link';

import { Player } from 'video-react';
// import AOS from 'aos';
import LogoImg from '../assets/images/my_logo.jpg';
import titleVid from '../assets/videos/imp_water.mp4';

//import titleVid from '../assets/images/backg.gif';
import '../pages/index.css';
//import './blog_landing.css';
/* Media */
import logoImg from '../assets/images/logo/logo.png';

import ban1 from '../assets/images/slider-image/02.jpg';
import ban2 from '../assets/images/slider-image/02.jpg';

import pImg1 from '../assets/images/product-image/AsthmaticaOil.png'
import Left_Menu_Option from '../sample_comp/left_menu_option';
import Blogs from '../sample_comp/blogs';
import Time_Line from '../sample_comp/time_line';

import '../sample_comp/why_us.css';
import './invoice_receipt.css';

import ImgQR from '../assets/images/receipt/qrcode.png';
import ImgSign from '../assets/images/receipt/signature.png';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let rightBarLists;
let blogData="";
let rf="0";
let queries;

let _netPayable=0;
class Invoice_Receipt extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };

    constructor(props){
        super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.state={viewData:"0",blogData:"",recentBlog:""};
        this.setState({stInvRep:[],
            stInvBreaks:[],
          stInvPurPro:[],
      stInvBAdd:[],
  stInvSAdd:[]});
        this.multiselectRef = React.createRef();
        loc=props.location["pathname"].substr(1);
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
                  if(queries.rf!=null){
                    rf=queries.rf;
                  }
                  if(typeof queries.rfkey!=="undefined"){
                    //empty cart here
                    localStorage.setItem("_cart",[]);
                  }
          }
        //alert(loc);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
         var listRoles= UserToken.getAccessRoles();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          rightBarLists= getRoleDetails(listRoles,loc);
         
          //window.location.href="./Dashboard";
        }
      }
componentDidMount(){
  clsObject=this;
  var mapCat=[];
  mapCat.push({name:"curl_type",value:"getProducts"});
  mapCat.push({name:"res_func",value:"callBackProList"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"p1",value:"new arrivals"});
  mapCat.push({name:"p2",value:"0"});
 // mapCat.push({name:"emp_id",value:userObject[0].id});
  mapCat["res_func"]="callBackProList";
  mapCat["curl_type"]="getProducts";
  CallMapMethod(clsObject,mapCat);
  AjaxCallMethod(clsObject,"okSuccCNOT");

  var mapCat=[];
  if(loc===""){
      loc="home";
  }
  //alert(loc);
  //loc="blogs_article"
  
  
}
callBackpgData(data,msg){
    //alert("testing of2 "+data);
    //$("#pgComp").html(data);
    var listObj= JSON.parse("{\"access\":"+data+"}");
    //alert("successfully parse");
    clsObject.getInvoicePg();
  

    //alert(JSON.stringify(blogData));
    var mainHt="";
    if(loc==="home"){
       //mainHt+=Video_Banner_Comp();
    }
    var injextI=0;
    var injCondition=false;
    var ifImgGallery=false;
    
    var strTopBanner="";
    for(var _dlist=0;_dlist<50;_dlist++){
     
        if(listObj.access[_dlist]){
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               //strTopBanner=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
               // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
            }else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }else if(listObj.access[_dlist].image_gallery){
                injCondition=true;
                ifImgGallery=true;
                mainHt+=ImageGallery(listObj.access[_dlist].image_gallery);
            }else if(listObj.access[_dlist].video_slider){
                //alert("I am in testing");
                injCondition=true;
                mainHt+=Video_Slider(listObj.access[_dlist].video_slider);
            }
            else if(listObj.access[_dlist].blogs){
                
                injCondition=true;
                mainHt+=Blogs(listObj.access[_dlist].blogs);
            }else if(listObj.access[_dlist].feature_web_list){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Feature_Web_List(listObj.access[_dlist].feature_web_list);
            }else if(listObj.access[_dlist].article_listing){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Article_Listing(listObj.access[_dlist].article_listing);
            }else if(listObj.access[_dlist].collapse_pan){
                injCondition=true;
                mainHt+=Collapse_Pan(listObj.access[_dlist].collapse_pan);
            }else if(listObj.access[_dlist].time_line){
                injCondition=true;
                mainHt+=Time_Line(listObj.access[_dlist].time_line);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    mainHt+=Features_Slider();
                    //alert(products);
                    mainHt+=products;
                }
           }
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    //alert(mainHt);
    //mainHt+=Product_Hori_Slider();

    //Condition for leftMenuOpt
    if(rightBarLists){
        var getLeftOpt=Left_Menu_Option(rightBarLists,loc);
    var mainDiv="<div class=\"shop-category-area pb-100px pt-10px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">";
    var mainCloseDiv="</div></div></div>";
    mainHt="<div class=\"col-lg-9 order-lg-last col-md-12 order-md-first\">"+
            mainHt+"</div>";
    mainHt=mainDiv+getLeftOpt+mainHt+mainCloseDiv;
      }
   mainHt=strTopBanner+mainHt;

    $("#pgComp").append(mainHt);

    RespBkMthdMapp(clsObject,"_addToCart","setCartBk");

    // AOS.init();
    // AOS.init({
    //   once: true
    // });
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);
      //this.forceUpdateHandler();
    if(ifImgGallery){
        $('.gallery-item').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }
  
}
setCartBk(refId){
   // alert(refId);
    this.setState(state =>({viewData:refId}));
    this.forceUpdate();
    $("#offcanvas-cart").addClass("offcanvas-open");
  }

  updatePaymentStatus(params,vref){
    //let tokenId=locUrl.pathname.split('/')[2];
    //let params= QueryString.parse(locUrl.search);
    //alert(params)
    // alert(JSON.stringify(vref));
    // return;
    if(params.redirect_status==="succeeded"){
        alert("Order placed successfully");
    }

    let mapStrip=[];
    mapStrip.push({name:"curl_type",value:"updateRefSecToken"});
    mapStrip.push({name:"res_func",value:"callBackSt"});
    mapStrip.push({name:"token",value:vref});
    mapStrip.push({name:"payment_intent",value:vref});
    mapStrip.push({name:"redirect_status",value:btoa(params.redirect_status)});
    mapStrip.push({name:"payment_intent_resp_secret",value:params.razorpay_payment_id});
    mapStrip.push({name:"payment_intent_client_secret",value:vref})

        mapStrip["res_func"]="callBackSt";
        mapStrip["curl_type"]="updateRefSecToken";
        ajaxindicatorstart("Wait for order processing..");
        CallMapMethod_CBack(this,mapStrip,function(data,msg){
            //alert(data);
            ajaxindicatorstop();
            if(data!=="0" && data!==""){
                //let jsData=JSON.parse(data);
                //const clientSecret = jsData.client_secret;
                //window.location.href="../invoice_receipt?rf="+params.razorpay_payment_id+"&rfkey="+vref;   
                window.location.href="../invoice_receipt?rf="+rf;
      
            }
        })
}

  RepeatePayNow(){
    {
        // alert(JSON.stringify(this.state.stInvRep));
         let rfInvoiceId=rf;
        // alert(rfInvoiceId);
        let p_contactNo="+91"+"8109448061";

        const script1 = document.createElement("script");
        script1.src = "https://checkout.razorpay.com/v1/checkout.js";
        script1.async = true;
       
        script1.onload = () =>{
          var options = {
           "key":"rzp_test_v2vCkjiwgSS8KO",
            //"key": "rzp_live_9jpRWSg5EIxaVl", // Enter the Key ID generated from the Dashboard
            "amount": parseFloat(this.state.stInvRep[0]["final_amount"])*100,
            "currency": "INR",
            "description": "Ayush Arogyam Pvt.Ltd.",
            "image": "https://test.ayusharogyam.com/logo.png",
            "prefill":
            {
              "email": "care@ayusharogyam.com",
              "contact": p_contactNo,
            },
            config: {
              display: {
                blocks: {
                  hdfc: { //name for HDFC block
                    name: "Pay using HDFC Bank",
                    instruments: [
                      {
                        method: "card",
                        issuers: ["HDFC"]
                      },
                      {
                        method: "netbanking",
                        banks: ["HDFC"]
                      },
                    ]
                  },
                  other: { //  name for other block
                    name: "Other Payment modes",
                    instruments: [
                      {
                        method: "card",
                        issuers: ["ICIC"]
                      },
                      {
                        method: 'netbanking',
                      }
                    ]
                  }
                },
                // hide: [
                //   {
                //   method: "upi"
                //   }
                // ],
                sequence: ["block.hdfc", "block.other"],
                preferences: {
                  show_default_blocks: true // Should Checkout show its default blocks?
                }
              }
            },
            "handler": function (response) {
              //alert("Order Submitted Successfully");
              //redirectConfirmation="orderConfirmation?rf="+data;
              response["redirect_status"]="succeeded";
              clsObject.updatePaymentStatus(response,rfInvoiceId);
              //window.location="orderConfirmation?rf="+data;
              //alert(JSON.stringify(response));
              //alert(response.razorpay_payment_id);
            },
            "modal": {
              "ondismiss": function () {
                if (window.confirm("Are you sure, you want to close the form?")) {
                  var txt = "You pressed OK!";
                  let response={};
                  response["razorpay_payment_id"]="0";
                  response["redirect_status"]="unpaid";
                
                  clsObject.updatePaymentStatus(response,rfInvoiceId);
                } else {
                  var txt = "You pressed Cancel!";
                  let response={};
                  response["razorpay_payment_id"]="0";
                  response["redirect_status"]="cancelled";
                  clsObject.updatePaymentStatus(response,rfInvoiceId);
                  //console.log("Complete the Payment")
                }
              }
            }
          };
    
    
          var rzp1 = new window.Razorpay(options);
          rzp1.open();
        };
      
        document.body.appendChild(script1);
      }
  }


getInvoicePg(){
   // alert(rf);
    var pgInv=[];
    pgInv.push({name:"curl_type",value:"invoice_receipt"});
    pgInv.push({name:"res_func",value:"callBackInvoicePg"});
  
 
    pgInv.push({name:'ref_id',value:rf});
    if(typeof queries.rfkey!=="undefined"){
       //for now commented
        //pgInv.push({name:"ref_trans_id",value:rf});
    }

 // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
  //mapCat.push({name:"emp_id",value:userObject[0].id});
  pgInv["res_func"]="callBackInvoicePg";
  pgInv["curl_type"]="invoice_receipt";
  if(rf!=="0" && rf.trim()!==""){
    //alert(rf);
    CallMapMethod(clsObject,pgInv);
  }
  
}
callBackInvoicePg(data,msg){
   // alert(data);
    let invRep=$($($.parseHTML(data)).filter("#d_inv")).text();
    let invBreaks=$($($.parseHTML(data)).filter("#d_invbreak")).text();
    let invPurPro=$($($.parseHTML(data)).filter("#d_purpro")).text();
    let invBAdd=$($($.parseHTML(data)).filter("#d_inv_bAdd")).text();
    let invSAdd=$($($.parseHTML(data)).filter("#d_inv_sAdd")).text();
    //alert(invRep);
    //alert(invBreaks);
    this.setState({stInvRep:JSON.parse(invRep),
                  stInvBreaks:JSON.parse(invBreaks),
                stInvPurPro:JSON.parse(invPurPro),
            stInvBAdd:JSON.parse(invBAdd),
        stInvSAdd:JSON.parse(invSAdd)});
    //alert(data);
    // var listObj= JSON.parse("{\"access\":"+data+"}");
    // //alert("successfully parse");
    // var tmpRecBlog=listObj.access;
    // //alert("length=="+tmpRecBlog.length);
    // this.setState({recentBlog:tmpRecBlog});
    // this.forceUpdateHandler();
}  
callBackProList(data,msg){
       //alert("testing=="+data);
    if(data!=="0"){
     var resPs= Product_Slider(data);
     //var resPs=Product_Listing(data);
     //alert(resPs);
     products+=resPs;
    //$("#pgComp").append(resPs);
    }
    var pgMap=[];
  pgMap.push({name:"curl_type",value:"getPgData"});
  pgMap.push({name:"res_func",value:"callBackpgData"});
  //alert(rf);
  if(rf!=="0"){
      pgMap.push({name:"ref_id",value:rf});
  }else{
    pgMap.push({name:"loc",value:loc});
  }
 // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
  //mapCat.push({name:"emp_id",value:userObject[0].id});
  pgMap["res_func"]="callBackpgData";
  pgMap["curl_type"]="getPgData";
  CallCMSMapMethod(clsObject,pgMap);
  }
saveEdu(){
  GetImgCertUser(clsObject,"#frmEdu","getTest","okEdut");
}
okEdut(data,datares,userResp){
  //alert(data);
}
    render(){
        return(<div>
          <Front_Header/>

          <div className="offcanvas-overlay"></div>
          <div className="blog-grid pb-100px pt-10px main-blog-page">
        <div className="container">
            <div className="row">
            
<Account_Opt_Link />

         <div id="pgComp_tmp" className="col-lg-12 col-md-12">
            
         <div className="ptrmain_div">
		<div>
			<div className="prt_head_div">
				<div style={{width: "60%",flex:"0 0 auto"}}>
					<h4 className="black">INVOICE{(this.state.stInvRep && this.state.stInvRep.lenght!=0?this.state.stInvRep.map((item,index)=>{
                        return <div>Payment Status-{item.payment_status} 
                        {item.payment_status!=="Paid"?<p><button onClick={()=>this.RepeatePayNow(item.enc_inv_id)} className="btn hover btn-primary btn-outline-dark current-btn" id="btnLoginEv" name="btnLoginEv" type="button"><span>Click to Pay Now</span></button></p>:''}
                        </div>
                    }):'')}</h4>
					<img style={{width:"140px",height:"auto",background:"#fff"}} src={logoImg} />

				</div>
				<div style={{width:"40%",flex:"0 0 auto",textAlign:"center"}}>
					<img width="130px" src={ImgQR} />
				</div>
			</div>
			<div className="row">
				<div className="col-md-4 p-5 border-box">
					<h4 class="black" style={{marginTop:"0px",marginBottom:"10px"}}>SOLD BY</h4>
					<p style={{marginTop:"5px"}}>Ayush Arogyam Pvt.Ltd.<br></br>
                    Asiatic Arcade, Pokhran Road no. 1,<br></br>
                    Vartak Nagar,<br></br>
                    Mumbai Thane - 400606 India<br></br>
						{/* Pincode- 33761<br></br> */}
						{/* Contact: 000000000<br></br> */}
						Email: <a href="mailto:sales@ayusharogyam.com">sales@ayusharogyam.com</a>
					</p>
				
				</div>
				<div class="col-md-4 p-5 border-box">
					<h4 class="black" style={{marginTop:"0",marginBottom:"10px"}}>SHIPPING ADDRESS</h4>
					{(this.state.stInvSAdd && this.state.stInvSAdd.lenght!=0?this.state.stInvSAdd.map((item,index)=>{
                    return <p style={{marginTop: "5px"}}>{item.name}<br></br>
                    Contact No:{item.mobile_no}
                    <br></br>
                     {item.address}<br></br>
                     {item.city+" "+item.state_nm+" "+item.pincode }
                 </p>
                    }):'')}
                    
				</div>
				<div class="col-md-4 p-5 border-box">
					<h4 class="black" style={{marginTop:"0",marginBottom:"10px"}}>BILLING ADDRESS</h4>
					{(this.state.stInvBAdd && this.state.stInvBAdd.lenght!=0?this.state.stInvSAdd.map((item,index)=>{
                     return <p style={{marginTop: "5px"}}>{item.name}<br></br>
                     Contact No:{item.mobile_no}<br></br>
                     {item.address}<br></br>
                     {item.city+" "+item.state_nm+" "+item.pincode }
                 </p>
    }):'')}
                    
				</div>
			</div>
			{(this.state.stInvRep && this.state.stInvRep.lenght!=0?this.state.stInvRep.map((item,index)=>{

			return <div style={{display:"flex",flexWrap:"wrap"}}>
				<div style={{width:"70%",flex:"0 0 auto",fontFamily:"Verdana, Geneva, sans-serif"}}>
					<h6 style={{margin:"10px 0"}}>ORDER No : {item.invoice_no} </h6>
					<h6 style={{margin:"10px 0"}}>ORDER DATE: {item.month_date}  </h6>
					<h6 style={{margin:"10px 0px 0px"}}>PLACE OF DELIVERY:{(this.state.stInvBAdd)?this.state.stInvBAdd[0].state_nm:''} </h6>
				</div>
				
				<div style={{width:"30%",flex:"0 0 auto",fontFamily:"Verdana, Geneva, sans-serif"}}>
					<h6 style={{margin:"10px 0"}}>INVOICE NO: {item.invoice_no} </h6>
					<h6 style={{margin:"10px 0px"}}>INVOICE DATE: {item.month_date} </h6>
				</div>
			</div>
            }):'')}
			<hr style={{borderColor:"black",marginBottom:"20px"}}></hr>
			

			{/* <!-- tablesection --> */}
            <div className="w-100 overflow-scroll">
			<table className="ptrTable table responsive">
			  <tr>
              <th>Code</th>
			    <th>Product</th>
			    <th>Qty</th>
			    <th>Price</th>
                <th>Amount</th>
			    <th>Discount</th>
			    <th>Payable Amount</th>
			  </tr>
              {(this.state.stInvPurPro && this.state.stInvPurPro.lenght!=0?this.state.stInvPurPro.map((item,index)=>{
                  let taxableColm="HSN:30049011 -GST "+item.tax_slab+"%";
                  if(item.igst_per!=0){
                      taxableColm="HSN:30049011 -IGST "+item.tax_slab+"%"
                  }
                return <tr>
                    <td>{item.product_code}</td>
                    <td>{item.product_name}</td>
			    <td>{item.quantity}</td>
                <td>{item.rate}</td>
			    <td>{item.amount} </td>
			    <td>{item.discount}</td>
			    <td>₹{item.final_amount}</td>
			  </tr>
              }):'')}
			  
			
<tr><td colspan="5"></td></tr>
			{(this.state.stInvRep && this.state.stInvRep.lenght!=0?this.state.stInvRep.map((item,index)=>{
_netPayable=item.final_amount;

             return <tr>
             <td></td>
             <td>TOTAL Cost</td>
             <td></td>
             <td></td>
             <td></td>
             
            <td></td>
             <td>₹{item.amount}</td>
           </tr>
            }):'')}
             {(this.state.stInvBreaks && this.state.stInvBreaks.lenght!=0?this.state.stInvBreaks.map((item,index)=>{
 return <tr>
 <td></td>
 <td>{item.break_type}</td>
 <td></td>
 <td>{item.break_type.toUpperCase()==="TAX"?item.tax_slab+"%":""}</td>
 <td>{item.break_down_amount}</td>
 <td>{""}</td>
 <td>₹{item.break_down_amount}</td>
</tr>
             }):'')}
            <tr>
                <td></td>
                <td>Net Payable</td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td><b>₹{_netPayable}</b></td>
            </tr>  
			  {/* {(this.state.stInvRep && this.state.stInvRep.lenght!=0?this.state.stInvRep.map((item,index)=>{
             return <tr>
             <td colspan="10">
                 Amount in Words:{item.amt_words} Only
             </td>
             
           </tr>
            }):'')}  */}
			 
			  {/* <tr>
			    <td colspan="6">
                <p style={{marginBottom:"10px"}}>REG NO: xxxDDDDYUI*<br></br>
						License No: 1345669009XX<br></br>
						DL No: 20B/XXX/CCC/123, TDCD/ZX/2333</p>
			    </td>
			    <td colspan="2">
			    	For Fast RC & Hobbies<br></br>
			    	<img height="70px" width="140px" src={ImgSign} /><br></br>
			    	Authorized Signatory
			    </td>
			  </tr> */}
			</table>
            </div>
			{/* <!-- endtable --> */}

			{/* <!-- footer --> */}
			<div style={{fontFamily:"Verdana, Geneva, sans-serif"}}>
				<h4 style={{marginBottom:"5px"}}> Declaration:</h4>
				<p style={{marginTop:"5px"}}>The goods sold are intended for end user consumption and not for resale. All disputes subject to Mumbai Jurisdiction only. </p>
			<hr></hr>
			<p style={{textAlign:"center"}}>Ayush Arogyam Pvt.Ltd.<br></br>
			29, Asiatic Arcade, Pokhran Road no. 1
Vartak Nagar,Mumbai Thane - 400606 INDIA</p>
			<hr></hr>
			</div>
			{/* <!-- endfooter --> */}
		</div>
	</div>
       
         </div>
         <div id="pgComp" className="col-lg-9 col-md-12"></div>
</div>
</div>
</div>
          <Footer/>
          <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog">
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                      <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><span
                              aria-hidden="true">x</span></button>
                  </div>
                  <div className="modal-body">
                      <div className="row">
                          <div className="col-md-5 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px">
                              
                              <div className="swiper-container gallery-top mb-4">
                                  <div className="swiper-wrapper">
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/1.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/2.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/3.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/4.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/5.jpg" alt=""/>
                                      </div>
                                  </div>
                              </div>
                              <div className="swiper-container gallery-thumbs slider-nav-style-1 small-nav">
                                  <div className="swiper-wrapper">
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/1.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/2.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/3.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/4.jpg" alt=""/>
                                      </div>
                                      <div className="swiper-slide">
                                          <img className="img-responsive m-auto" src="assets/images/product-image/5.jpg" alt="" />
                                      </div>
                                  </div>
                                 
                                  <div className="swiper-buttons">
                                      <div className="swiper-button-next"></div>
                                      <div className="swiper-button-prev"></div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-7 col-sm-12 col-xs-12">
                              <div className="product-details-content quickview-content">
                                  <h2>Originals Kaval Windbr</h2>
                                  <p className="reference">Reference:<span> demo_17</span></p>
                                  <div className="pro-details-rating-wrap">
                                      <div className="rating-product">
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                          <i className="ion-android-star"></i>
                                      </div>
                                      <span className="read-review"><a className="reviews" href="#">Read reviews (1)</a></span>
                                  </div>
                                  <div className="pricing-meta">
                                      <ul>
                                          <li className="old-price not-cut">₹18.90</li>
                                      </ul>
                                  </div>
                                  <p className="quickview-para">Lorem ipsum dolor sit amet, consectetur adipisic elit eiusm tempor incidid ut labore et dolore magna aliqua. Ut enim ad minim venialo quis nostrud exercitation ullamco</p>
                                  <div className="pro-details-size-color">
                                      <div className="pro-details-color-wrap">
                                          <span>Color</span>
                                          <div className="pro-details-color-content">
                                              <ul>
                                                  <li className="blue"></li>
                                                  <li className="maroon active"></li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="pro-details-quality">
                                      <div className="cart-plus-minus">
                                          <input className="cart-plus-minus-box" type="text" name="qtybutton" value="1" />
                                      </div>
                                      <div className="pro-details-cart btn-hover">
                                          <button className="add-cart btn btn-primary btn-hover-primary ml-4"> Add To
                                              Cart</button>
                                      </div>
                                  </div>
                                  <div className="pro-details-wish-com">
                                      <div className="pro-details-wishlist">
                                          <a href="wishlist.html"><i className="ion-android-favorite-outline"></i>Add to
                                              wishlist</a>
                                      </div>
                                      <div className="pro-details-compare">
                                          <a href="compare.html"><i className="ion-ios-shuffle-strong"></i>Add to compare</a>
                                      </div>
                                  </div>
                                  <div className="pro-details-social-info">
                                      <span>Share</span>
                                      <div className="social-info">
                                          <ul>
                                              <li>
                                                  <a href="#"><i className="ion-social-facebook"></i></a>
                                              </li>
                                              <li>
                                                  <a href="#"><i className="ion-social-twitter"></i></a>
                                              </li>
                                              <li>
                                                  <a href="#"><i className="ion-social-google"></i></a>
                                              </li>
                                              <li>
                                                  <a href="#"><i className="ion-social-instagram"></i></a>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
          </div>
          
          );
    }
}
export default Invoice_Receipt