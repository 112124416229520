import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import UserToken from '../function_lib/userToken';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser,
    ajaxindicatorstart,ajaxindicatorstop} from '../function_lib/common_lib';
import {getRoleDetails,CallCMSMapMethod} from '../function_lib/common_cms';
import Product_Listing from '../sample_comp/product_listing';
import Front_Header from '../pages/front_header';
import Footer from '../pages/footer';
import Top_Banner from '../sample_comp/top_banner';
import Top_Specified from '../pages/top_specified';
import Home_Product from '../pages/home_product';
import Product_Slider from '../sample_comp/product_slider';
import Features_Slider  from '../sample_comp/features_slider';
import Feature_Web_List from '../sample_comp/feature_web_list';
import Article_Listing from '../sample_comp/article_listing';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import ImageGallery from '../sample_comp/image_gallery';
import Video_Slider from '../sample_comp/video_slider';
import Collapse_Pan from '../sample_comp/collapse_pan';

import Comp_Blog_Land from '../sample_comp/comp_blog_land';
import Account_Opt_Link from './account_opt_link';

import Comp_Order_Track from '../component_version2/comp_order_track';

import { Player } from 'video-react';
// import AOS from 'aos';
import LogoImg from '../assets/images/my_logo.jpg';
import titleVid from '../assets/videos/imp_water.mp4';

//import titleVid from '../assets/images/backg.gif';
import '../pages/index.css';
//import './blog_landing.css';
/* Media */
import logoImg from '../assets/images/logo/logo.png';

import ban1 from '../assets/images/slider-image/02.jpg';
import ban2 from '../assets/images/slider-image/02.jpg';

import pImg1 from '../assets/images/product-image/AsthmaticaOil.png'
import Left_Menu_Option from '../sample_comp/left_menu_option';
import Blogs from '../sample_comp/blogs';
import Time_Line from '../sample_comp/time_line';

import '../sample_comp/why_us.css';
import './my_orders.css';


let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let rightBarLists;
let blogData="";
let rf="0";
let queries;
const GetTrackingNo=(props)=>{

if(props.track_Data.track_url!==""){
    let lsShipping=JSON.parse(props.track_Data.track_url);
    return (<div>
        {lsShipping.map((item,index)=>{
            return <a  onClick={()=>clsObject.getOrderTrack(props.track_Data)} data-toggle="modal" data-target="#divTrackOrder" href="javascript:void(0)" class="linkclass">{item.order_no}</a>
        })}
    </div>);
}else{
    return (<div>Under Process</div>);
}
}
class My_Orders extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };

    constructor(props){
        super(props);
        clsObject=this;
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        this.state={viewData:"0",blogData:"",recentBlog:"",stTrackOrder:{}};
        this.state={stInvRep:[]};
        this.multiselectRef = React.createRef();
        loc=props.location["pathname"].substr(1);
        if(props !=null){
            queries = QueryString.parse(this.props.location.search);
                  if(queries.rf!=null){
                    rf=queries.rf;
                  }
          }
        //alert(loc);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
         var listRoles= UserToken.getAccessRoles();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          userObject= getUserObjData();
          rightBarLists= getRoleDetails(listRoles,loc);
         
          //window.location.href="./Dashboard";
        }
      }
componentDidMount(){
  
  var mapCat=[];
  mapCat.push({name:"curl_type",value:"getProducts"});
  mapCat.push({name:"res_func",value:"callBackProList"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"p1",value:"new arrivals"});
  mapCat.push({name:"p2",value:"0"});
 // mapCat.push({name:"emp_id",value:userObject[0].id});
  mapCat["res_func"]="callBackProList";
  mapCat["curl_type"]="getProducts";
  CallMapMethod(clsObject,mapCat);
  AjaxCallMethod(clsObject,"okSuccCNOT");

  var mapCat=[];
  if(loc===""){
      loc="home";
  }
  //alert(loc);
  //loc="blogs_article"
  
  
}
callBackpgData(data,msg){
    //alert("testing of2 "+data);
    //$("#pgComp").html(data);
    var listObj= JSON.parse("{\"access\":"+data+"}");
    //alert("successfully parse");
    clsObject.getInvoicePg();

    //alert(JSON.stringify(blogData));
    var mainHt="";
    if(loc==="home"){
       //mainHt+=Video_Banner_Comp();
    }
    var injextI=0;
    var injCondition=false;
    var ifImgGallery=false;
    
    var strTopBanner="";
    for(var _dlist=0;_dlist<50;_dlist++){
     
        if(listObj.access[_dlist]){
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               //strTopBanner=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
               // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
            }else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }else if(listObj.access[_dlist].image_gallery){
                injCondition=true;
                ifImgGallery=true;
                mainHt+=ImageGallery(listObj.access[_dlist].image_gallery);
            }else if(listObj.access[_dlist].video_slider){
                //alert("I am in testing");
                injCondition=true;
                mainHt+=Video_Slider(listObj.access[_dlist].video_slider);
            }
            else if(listObj.access[_dlist].blogs){
                
                injCondition=true;
                mainHt+=Blogs(listObj.access[_dlist].blogs);
            }else if(listObj.access[_dlist].feature_web_list){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Feature_Web_List(listObj.access[_dlist].feature_web_list);
            }else if(listObj.access[_dlist].article_listing){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Article_Listing(listObj.access[_dlist].article_listing);
            }else if(listObj.access[_dlist].collapse_pan){
                injCondition=true;
                mainHt+=Collapse_Pan(listObj.access[_dlist].collapse_pan);
            }else if(listObj.access[_dlist].time_line){
                injCondition=true;
                mainHt+=Time_Line(listObj.access[_dlist].time_line);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    mainHt+=Features_Slider();
                    //alert(products);
                    mainHt+=products;
                }
           }
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    //alert(mainHt);
    //mainHt+=Product_Hori_Slider();

    //Condition for leftMenuOpt
    if(rightBarLists){
        var getLeftOpt=Left_Menu_Option(rightBarLists,loc);
    var mainDiv="<div class=\"shop-category-area pb-100px pt-10px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">";
    var mainCloseDiv="</div></div></div>";
    mainHt="<div class=\"col-lg-9 order-lg-last col-md-12 order-md-first\">"+
            mainHt+"</div>";
    mainHt=mainDiv+getLeftOpt+mainHt+mainCloseDiv;
      }
   mainHt=strTopBanner+mainHt;

    $("#pgComp").append(mainHt);

    RespBkMthdMapp(clsObject,"_addToCart","setCartBk");

    // AOS.init();
    // AOS.init({
    //   once: true
    // });
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);
      //this.forceUpdateHandler();
    if(ifImgGallery){
        $('.gallery-item').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }
  
}
setCartBk(refId){
   // alert(refId);
    this.setState(state =>({viewData:refId}));
    this.forceUpdate();
    $("#offcanvas-cart").addClass("offcanvas-open");
  }

callBackProList(data,msg){
       //alert("testing=="+data);
    if(data!=="0"){
     var resPs= Product_Slider(data);
     //var resPs=Product_Listing(data);
     //alert(resPs);
     products+=resPs;
    //$("#pgComp").append(resPs);
    }
    var pgMap=[];
  pgMap.push({name:"curl_type",value:"getPgData"});
  pgMap.push({name:"res_func",value:"callBackpgData"});
  //alert(rf);
  if(rf!=="0"){
      pgMap.push({name:"ref_id",value:rf});
  }else{
    pgMap.push({name:"loc",value:loc});
  }
 // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
  //mapCat.push({name:"emp_id",value:userObject[0].id});
  pgMap["res_func"]="callBackpgData";
  pgMap["curl_type"]="getPgData";
  CallCMSMapMethod(clsObject,pgMap);

  }

  
getInvoicePg(){
    //alert(rf);
    var pgInv=[];
    pgInv.push({name:"curl_type",value:"getInvoicesByRef"});
    pgInv.push({name:"res_func",value:"callBackInvoicePg"});
  
 
    pgInv.push({name:'loc_token',value:userObject[0]["id"]});

 // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
  //mapCat.push({name:"emp_id",value:userObject[0].id});
  pgInv["res_func"]="callBackInvoicePg";
  pgInv["curl_type"]="getInvoicesByRef";
  CallMapMethod(clsObject,pgInv);
  ajaxindicatorstart("loading data..");
}
callBackInvoicePg(data,msg){
    ajaxindicatorstop();
  //alert(data);
    this.setState({stInvRep:JSON.parse(data),
                });
    //alert(data);
    // var listObj= JSON.parse("{\"access\":"+data+"}");
    // //alert("successfully parse");
    // var tmpRecBlog=listObj.access;
    // //alert("length=="+tmpRecBlog.length);
    // this.setState({recentBlog:tmpRecBlog});
    // this.forceUpdateHandler();
}

getOrderTrack(data){
    this.setState({stTrackOrder:data});
    
}


saveEdu(){
  GetImgCertUser(clsObject,"#frmEdu","getTest","okEdut");
}
okEdut(data,datares,userResp){
  //alert(data);
}
    render(){
        return(<div>
          <Front_Header/>

          <div className="offcanvas-overlay"></div>
          <div className="blog-grid pb-100px pt-10px main-blog-page">
        <div className="container">
            <div className="row">
            

<div id="pgComp_tmp" className="col-lg-12 col-md-12">
<Account_Opt_Link />            
<div className="cart-main-area pt-20px pb-20px">
        <div className="container-sm">
            <h3 className="cart-page-title">My Orders/Invoices</h3>
            {/* <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12"> */}
                    <form action="#">
                    <div class="pt-2 invoicecard">
                    {(this.state.stInvRep && this.state.stInvRep.length!=0?this.state.stInvRep.map((item,index)=>{                    
    return <div class="row ps-4 border-box">
        <div class="col-md-12 invoiceim pb-2">
               <div class="img-container float-left d-flex">
                <span><i class="fa fa-shopping-cart fa-2x pr-2" style={{"padding-top":"50%"}} aria-hidden="true"></i></span>
                    <span class="text-success pt-2">Order Status:{item.order_status} <br></br>
                    Date-time {item.month_date}
                    </span>
                </div>
        </div>

        <div class="col-md-12 ps-4">
            <div class="row">
            <div class="col-md-2 d-flex-mob">
                <h6 className="pt-2">Traking Number</h6>
               <span className="pt-2">
                <GetTrackingNo track_Data={item}/>
               </span>
            </div>
            <div class="col-md-2 d-flex-mob">
                <h6 className="pt-2">Order Number</h6>
               <span className="pt-2">{item.invoice_no}</span>
            </div>
            
            <div class="col-md-2 d-flex-mob">
                <h6 className="pt-2">MRP</h6>
                <span className="pt-2">₹ {item.final_amount}</span>
            </div>
            <div class="col-md-2 d-flex-mob">
                <h6 className="pt-2">Payment Status</h6>
               <span className="pt-2">{item.payment_status}</span>
            </div>
            <div className="col-md-4 d-flex-mob">
        <a  onClick={()=>this.getOrderTrack(item)} data-toggle="modal" data-target="#divTrackOrder" href="javascript:void(0)" class="float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">Track</a>
        <a href={"./invoice_receipt?rf="+item.rf_id} class="float-right btn-sm btn-lg btn-primary btn-hover-dark mt-5">View</a>
        </div> 
        </div>
       </div>
    </div>
    
                  }):'')}
   
</div>
                    </form>
                {/* </div> */}
            {/* </div> */}
        </div>
    </div>
       
         </div>

         <div id="pgComp" className="col-lg-9 col-md-12">
            
        
       
         </div>
        
</div>
</div>
</div>
          <Footer/>
          <Comp_Order_Track isTrackable={true} trackOrder={this.state.stTrackOrder}/>
          </div>
          
          );
    }
}
export default My_Orders