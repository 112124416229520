import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './left_img_txt_desc.css';
//import lefImg1 from '../assets/images/about-image/1.jpg';
export function Left_Img_Txt_Desc(lsData,lsType="left"){
    var appPath= appGetUrlPath();
    var strResp="";
    let strBtn="";

    let strLeft="";
    let strRight="";
    let isBackgroundCol12="";
    for(var i=0;i<lsData.length;i++){
        if(lsData[i]["col12"]!==""){
            if(lsData[i]["col12"]==="default-image"){
                isBackgroundCol12="bg-area1";
            }
        }
        strBtn="";

        if(lsData[i]["col7"].trim()!==""){
            strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" href=\""+lsData[i]["col7"]+"\">"+lsData[i]["col8"]+"</a>";
            if(lsData[i]["col7"].trim().indexOf("#")!==-1){
                strBtn="<a type=\"button\" class=\"btn text-center btn-primary btn-hover-primary ml-4\" data-toggle=\"modal\" data-target=\""+lsData[i]["col7"].trim()+"\" href=\"javascript:void(0)\">"+lsData[i]["col8"]+"</a>";
            }
        }
strLeft="<section class=\"left_img_txt_desc text-center border-box111 "+isBackgroundCol12+" about-area pb-70px pt-70px\">"+
"<div class=\"container\">"+
    "<div class=\"row align-items-center\">"+
        "<div class=\"col-lg-6\">"+
            "<div class=\"about-left-image  mb-md-30px mb-lm-30px\" >"+
                "<img  src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
            "</div>"+
        "</div>"+
        "<div class=\"col-lg-6\">"+
            "<div class=\"about-content\">"+
                "<div class=\"about-title\">"+
                    "<h3>"+lsData[i]["col1"]+"</h3>"+
                "</div>"+
                "<p class=\"mb-6\">"+
                   lsData[i]["col2"]+lsData[i]["col3"]+
                "</p>"+
                "<p>"+
                    strBtn+
                "</p>"+
            "</div>"+
       "</div>"+
    "</div>"+
"</div>"+
"</section>";

strRight="<section class=\"left_img_txt_desc border-box-none about-area "+isBackgroundCol12+" pb-70px pt-70px\">"+
"<div class=\"container\">"+
    "<div class=\"row align-items-center\">"+
       
        "<div class=\"col-lg-6\">"+
            "<div class=\"about-content\">"+
                "<div class=\"about-title\">"+
                    "<h3>"+lsData[i]["col1"]+"</h3>"+
                "</div>"+
                "<p class=\"mb-6\">"+
                   lsData[i]["col2"]+lsData[i]["col3"]+
                "</p>"+
                "<p>"+
                    strBtn+
                "</p>"+
            "</div>"+
       "</div>"+
       "<div class=\"col-lg-6\">"+
       "<div class=\"about-left-image text-center mb-md-30px mb-lm-30px\">"+
           "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" class=\" img-responsive w-100\" />"+
       "</div>"+
   "</div>"+
    "</div>"+
"</div>"+
"</section>";

//alert(lsData[i]["col7"].trim());
if(lsType==="left"){
    if(i%2==0){
        
        strResp+=strLeft;
    }else{
        strResp+=strRight;
    }
}else{
    if(i%2==0){
        
        strResp+=strRight;
    }else{
        strResp+=strLeft;
    } 
}
      
        
    }
    return strResp;
}

export default Left_Img_Txt_Desc;