import React from 'react';
export const Comp_Order_Track=(props)=>{
    let orderNos=[];
    //alert(JSON.stringify(props.trackOrder));
    if(typeof props.trackOrder!=="undefined"){
        //orderNos=props.trackOrder.order_no.split(',');
        if(props.trackOrder.track_url!==""){
            //alert(JSON.stringify(props.trackOrder.track_url));
            orderNos=JSON.parse(props.trackOrder.track_url);

        }
        
        
        //alert("test=="+orderNos);
    }
    return(<div className="modal fade" id="divTrackOrder" tabindex="-1" role="dialog">
    {typeof props.trackOrder!=="undefined"?
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                
                <button type="button" className="close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">x</span></button>
            </div>
            <div className="modal-body">
                <div className="row">
                    {/* <div className="col-md-5 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px">
                        
                        
                      
                    </div> */}
                    
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="product-details-content quickview-content">
                            <h2></h2>
                            <p className="reference">Invoice No:<span>{props.trackOrder.invoice_no}</span></p>
                            <div className="pro-details-rating-wrap">
                                {/* <div className="rating-product">
                                    <i className="ion-android-star"></i>
                                    <i className="ion-android-star"></i>
                                    <i className="ion-android-star"></i>
                                    <i className="ion-android-star"></i>
                                    <i className="ion-android-star"></i>
                                </div> */}
                                {/* <span className="read-review"><a className="reviews" href="#">Read reviews (1)</a></span> */}
                            </div>
                            <div className="pricing-meta">
                                <ul>
                                    <li className="old-price not-cut">Order Status:{props.trackOrder.order_status}</li>
                                </ul>
                            </div>
                            {props.trackOrder.order_status==="Delivered"?<p>Your order is delivered, if you havn't received it, contact the shipping service provider</p>:''}
                            {
                            (props.trackOrder.track_url.trim()==="" || props.trackOrder.order_no.trim()==="")
                            ?<p className="quickview-para">Your order is under process to dispatch, As soon as it will dispatched you will get Tracking No List, Thanks.</p>:
                            <p>Your order is under process to dispatch, As soon as it will dispatched you will get Tracking No List, Thanks</p>
                            }
                            {(props.trackOrder.track_url.trim()!=="" || props.trackOrder.order_no.trim()) &&
                            props.isTrackable===false?
    
<table className="table table-bordered table-striped table-sm">
                            <thead>
                                <tr style={{"background-color":"#FC766AFF"}}>
                                    <td>Tracking No</td>
                                    <td>Tracking Url</td>
                                    <td>Shipment Provider</td>
                                </tr>
                            </thead>
                            <tbody>
                                {orderNos.map((item,index)=>{
                                    return <tr>
                                        <td>{item.order_no}</td>
                                        <td><a target="blank" href={item.url}>{item.url}</a></td>
                                        <td>{item.title}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>:(props.trackOrder.track_url.trim()!=="" || props.trackOrder.order_no.trim())?
                        <div>
                              
                        {orderNos.map((item,index)=>{
                            return <iframe height={"500px"} width={"100%"} src={item.url+"\\"+item.order_no+"#toolbar=0&navpanes=0"}></iframe>
                        })}
                        </div>
                        :''    
                        }
                     </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>:''}
    </div>)
}
export default Comp_Order_Track;