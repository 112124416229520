import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {appGetUrlPath,CallMapMethod} from '../function_lib/common_lib';
import './cart_canvas.css';
let cartItems;
let proData;
let finalAmt=0;
let getUser;
window.cAmount="0";
let uri;
let appUrtPath;
let clsObject;
let couponDiscount=0;
class Cart_Canvas extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
      RmElement(index){
        //alert("i m delete "+index);
        cartItems=localStorage.getItem("_cart");
        if(cartItems!=null && cartItems!==""){
           let getItem=JSON.parse(cartItems);
            var items=[];
            for(var i=0;i<getItem.length;i++){
                //alert(getItem[i].item_name);
                if(i!=index){
                    items.push({"item_name":getItem[i].item_name,"item_code":getItem[i].item_code,
                    "item_rate":getItem[i].item_rate,"item_quantity":getItem[i]["item_quantity"],"amount":getItem[i]["amount"],"company_name":getItem[i].company_name,"item_rf_id":getItem[i].item_rf_id,"img_c":getItem[i].img_c});
                }
             
              }
              localStorage.setItem("_cart",JSON.stringify(items));
              cartItems=localStorage.getItem("_cart");
              cartItems=JSON.parse(cartItems);
              //alert(cartItems.length);
             this.setState({setCartItems:cartItems,isUpdate:true});
             finalAmt=0;
             $("._cart_elm_count").html("0");
             //alert("after delete = "+this.state.setCartItems.length)
             this.forceUpdateHandler();
              //window.location.reload();
        }
      }
      componentWillReceiveProps(props){
        cartItems=localStorage.getItem("_cart");
        if(cartItems!=null && cartItems!==""){
          cartItems=JSON.parse(cartItems);
          // alert(cartItems.length);
          this.setState({setCartItems:cartItems,isUpdate:true});
          finalAmt=0;
          $("._cart_elm_count").html("0");
          this.forceUpdateHandler();
        }
        
      }
    constructor(props){
        super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        appUrtPath=appGetUrlPath();
        this.state={setCartItems:[],isUpdate:false};
        uri="/login?rf=5cCsC5JlrxcTKe";
        getUser=getUserObjData();
        clsObject=this;
    if(getUser!==""){
       
      uri="/steptwo?rf="+getUser[0].id;
    }

    }
    checkCouponCode(){
        let mapCat=[];
        mapCat.push({name:"curl_type",value:"checkCouponCode"});
        mapCat.push({name:"res_func",value:"callBackProCoupon"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"p1",value:$("#coupon_disc").val()});
        mapCat.push({name:"p2",value:finalAmt});
        mapCat["res_func"]="callBackProCoupon";
        mapCat["curl_type"]="checkCouponCode";
       CallMapMethod(clsObject,mapCat);
    }
    callBackProCoupon(data,msg){
        //alert(data);
        if(data!=="0"){
            let couponData=JSON.parse(data);
            //alert(couponData["amount"]);
            if(cartItems!=null && cartItems!==""){
                cartItems.push({"item_name":couponData["item_name"],"item_code":couponData["item_code"],
                "item_rate":couponData["amount"],"item_quanity":"1","amount":couponData["amount"],"company_name":"0","item_rf_id":"0","img_c":""});
                   }
                   localStorage.setItem("_cart",JSON.stringify(cartItems));
                   //this.setState(state=>({setCartItems:cartItems,isUpdate:true}));
                   this.setState({setCartItems:cartItems,isUpdate:true});
                   this.forceUpdateHandler();
            }
           
    } 
    componentDidMount(){
        cartItems=localStorage.getItem("_cart");
    if(cartItems!=null && cartItems!==""){
      
   
      cartItems=JSON.parse(cartItems);
      // alert(cartItems.length);
      this.setState(state=>({setCartItems:cartItems,isUpdate:true}));
      finalAmt=0;
      $("._cart_elm_count").html("0");
      this.forceUpdateHandler();
      //alert("checkcount=="+this.state.setCartItems.length+"="+this.state.isUpdate);
       if(cartItems.length!=0){
        //$("._cart_elm_count").html(cartItems.length); 
       }
    }
    }
    render(){
        return(<div id="offcanvas-cart" className="offcanvas offcanvas-cart">
<div className="inner">
    <div className="head">
        <span className="title">Cart</span>
        <a href="../checkout_cart" className="linkclass">View Cart</a>
        <button className="offcanvas-close">×</button>
    </div>
    <div className="body customScroll">
        <ul className="minicart-product-list">
        {(cartItems) ? cartItems.map((item,index)=>{
            let proUrl= encodeURI("../product/"+item.item_name+"?rf="+item.enc_tmp_id);
            if(index==0){
                finalAmt=0;
            }
                    if(item.item_name==="coupon discount"){
                        couponDiscount=item.item_rate;
                       
                    }else{
                        finalAmt+=parseFloat(item.amount);
                    }
                     
                     window.cAmount=finalAmt;
                     $("._cart_elm_sun").html("$"+finalAmt);
                     $("._cart_elm_count").html(index+1);
            return (item.item_name!=="coupon discount"?<li>
            <a href={proUrl} className="image"><img src={appUrtPath+item.img_c} alt={item.item_name} /></a>
            <div className="content d-flex" style={{"flex-direction":"column"}}>
                <a href={proUrl} style={{"float":"left"}} className="title px-2">{item.item_name}</a>
                <span className="quantity-price">{item.item_quantity} x {item.item_rate}<span className="amount"> = ₹{item.amount}</span></span>
                
                <a href="javascript:void(0)" onClick={()=>this.RmElement(index)} param1={index} className="remove"><i class="icon-close"></i></a>
            </div>
        </li>:'')
             }
             ):''
           }
        </ul>
    </div>
    <div className="foot">
        {/* {(getUser!=="" && couponDiscount===0 && cartItems!=null && cartItems!==""?<div className='container sub-total coupon_apply row'>
          <div className='col-6'>
          <input type='text' id="coupon_disc" name="coupon_disc" placeholder='coupon code' />
              </div>
              <div className='col-6'>
            <button type='button' onClick={()=>this.checkCouponCode()} className='btn text-center btn-primary btn-hover-primary mr-4'>Apply</button>
            </div>  
         
        </div>:'')} */}
        
        <div className="sub-total">
            <table className="table">
                <tbody>
                    <tr>
                        <td className="text-left">Sub-Total :</td>
                        <td className="text-right">₹{finalAmt}</td>
                    </tr>
                    {couponDiscount!==0?<tr>
                        <td className="text-left" >Coupon Discount</td>
                        <td className="text-right">-₹{parseInt(couponDiscount)}</td>
                    </tr>:''}
                    <tr>
                        <td className="text-left">Total :</td>
                        <td className="text-right theme-color">₹{finalAmt-parseInt(couponDiscount)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        {this.state.setCartItems.length===0?
        <div className="buttons d-flex">

            {/* <a href={uri} className="btn btn-dark btn-hover-primary mb-6">view cart</a> */}
            <a href={"javascript:void(0)"} className="btn btn-outline-dark cart-shiping-update col">View Cart</a>
            <a href={"javascript:void(0)"} className="btn btn-outline-dark current-btn col">checkout</a>
            
        </div>:<div className="buttons d-flex">

{/* <a href={uri} className="btn btn-dark btn-hover-primary mb-6">view cart</a> */}
<a href={"../checkout_cart"} className="btn btn-outline-dark cart-shiping-update col">View Cart</a>
<a href={uri} className="btn btn-outline-dark current-btn col">checkout</a>

</div>}
        <p className="minicart-message">Free Shipping on All Orders Over ₹ 1999!</p>
    </div>
</div>
</div>);
    }
}



export default Cart_Canvas;